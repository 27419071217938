*,
html,
body {
  font-family: "SomeArabic";
}

@font-face {
  font-family: "SomeArabic";
  src: local("SomeArabic"),
    url(./utils/fonts/din-next-lt-arabic-light.ttf) format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "SomeArabic";
  src: local("SomeArabic"),
    url(./utils/fonts/din-next-lt-w23-black.ttf) format("woff");
  font-weight: bold;
  font-style: normal;
}
/* for tinymce */
iframe,
object,
embed {
  max-width: 100%;
  max-height: 100%;
}
a {
  color: #333333;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}
p {
  font-size: 1.3em;
  line-height: 1.2em;
}
.bg-desert {
  background: #ff7e51;
}
.color-desert {
  color: #ff7e51;
}

.bg-purple {
  background-color: #7770d0;
}
.color-purple {
  color: #7770d0;
}
.mainPage a:hover {
  color: #ff7e51;
}
.mainPage p {
  color: darkgrey;
}
.bg-gold {
  background-color: #ffc107;
}
.color-gold {
  color: #ffc107;
}

.color-black {
  color: #333333;
}
.bg-black {
  background-color: #333333;
}

.bg-blue {
  background-color: #0c59bc;
}
.color-blue {
  color: #0c59bc;
}

.bg-lightgrey {
  background-color: #eaeaea;
}
.color-lightgrey {
  color: #eaeaea;
}
